import React from "react";
import { graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import parse, { attributesToProps, domToReact } from "html-react-parser";

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import { Layout } from "../components";
import { TramOutlined } from "@mui/icons-material";

const PageTemplate = ({ data: { previous, next, post } }) => {
  	const featuredImage = {
		data: post.featuredImage?.node?.localFile?.childImageSharp?.gatsbyImageData,
		alt: post.featuredImage?.node?.alt || ``,
  	}

  	return (
		<Layout title={post.title}>
			<Box
				//className="blog-post"
				itemScope
				itemType="http://schema.org/Article"
			>
				<Box>
					<Typography 
						component="h1" 
						gutterBottom={TramOutlined}
						itemProp="headline" 
						variant="h4"
					>
						{parse(post.title)}
					</Typography>
					{featuredImage?.data && (
						<GatsbyImage
							alt={featuredImage.alt}
							image={featuredImage.data}
						/>
					)}
				</Box>
				{!!post.content && (
					<Box itemProp="articleBody">{parse(post.content, {
						replace: domNode => {
						  	if (domNode.attribs && domNode.name === 'p') {
								const props = attributesToProps(domNode.attribs);
								const children = domNode.children;
								return <Typography {...props}>{domToReact(children)}</Typography>;
						  	}
						}})}
					</Box>
				)}
			</Box>
		</Layout>
  	)
}

export default PageTemplate;

export const pageQuery = graphql`
  	query PageById(
		$id: String!
		$previousPostId: String
		$nextPostId: String
  	) {
		post: wpPage(id: { eq: $id }) {
			id
			content
			title
			date(formatString: "MMMM DD, YYYY")
			featuredImage {
				node {
					altText
					localFile {
						childImageSharp {
							gatsbyImageData(
								quality: 100
								placeholder: TRACED_SVG
								layout: FULL_WIDTH
							)
						}
					}
				}
			}
		}
		previous: wpPost(id: { eq: $previousPostId }) {
		uri
		title
		}
		next: wpPost(id: { eq: $nextPostId }) {
		uri
		title
		}
  	}
`;